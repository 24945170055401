import { css } from '@emotion/react'
import { TableCell } from '@mui/material'
import { Text } from '@orus.eu/pharaoh'
import type { ReactElement } from 'react'
import { formatDateTime } from '../../../lib/format'

export type TableCellDateProps = {
  dateTime: Date | number | null | undefined
}

export function TableCellDateTime({ dateTime }: TableCellDateProps): ReactElement {
  return (
    <TableCell
      css={css`
        margin-top: 0;
      `}
    >
      <Text variant="body2">{formatDateTime(dateTime)}</Text>
    </TableCell>
  )
}
